import axios from "../../utils/request";

//报警查询
export function warningQuery(data) {
  return axios({
    url: "/clodchainAM/web/warningPage",
    method: "get",
    params: data
  });
}
//导出报警信息
export function exportWarnings(data) {
  return axios({
    url: "/clodchainAM/web/exportWarning",
    method: "post",
    data: data,
    responseType: "blob"
  });
}
//删除报警信息
export function deleteWarning(data) {
  return axios({
    url: "/clodchainAM/web/exportWarning",
    method: "post",
    data: data
  });
}

//编辑报警信息

export function editWarning(data) {
  return axios({
    url: "/clodchainAM/web/updateWarning",
    method: "post",
    data: data
  });
}
