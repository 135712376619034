<template>
  <div>
    <div class="condition">
      <el-row>
        <el-col :span="24">
          <el-input
            v-model="keyword"
            class="content_input content_item"
            placeholder="请输入设备编码或关键字"
          ></el-input>
          <el-select
            v-model="state"
            class="content_state content_item"
            placeholder="请选择处理状态"
            :clearable="true"
          >
            <el-option
              v-for="item in warningStatusItem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!--             报警类型 0：全部告警解除、1：温度高温报警、2：温度高温报警解除、3：温度低温告警、4：温度低温告警解除、-->
          <!--              5：通讯异常告警、6：通讯异常告警解除、7：探头故障告警、8：探头故障告警解除、9：位置异常报警、10：断电报警、11：流量服务中止报警';-->
          <el-select
            v-model="warningType"
            class="content_state content_item"
            placeholder="请选择报警类型"
            :clearable="true"
          >
            <el-option label="全部告警解除" value="0"></el-option>
            <el-option label="温度高温报警" value="1"></el-option>
            <el-option label="温度高温报警解除" value="2"></el-option>
            <el-option label="温度低温告警" value="3"></el-option>
            <el-option label="温度低温告警解除" value="4"></el-option>
            <el-option label="通讯异常告警" value="5"></el-option>
            <el-option label="通讯异常告警解除" value="6"></el-option>
            <el-option label="探头故障告警" value="7"></el-option>
            <el-option label="探头故障告警解除" value="8"></el-option>
            <el-option label="位置异常报警" value="9"></el-option>
            <el-option label="断电报警" value="10"></el-option>
            <el-option label="流量服务中止报警" value="11"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <span class="warning_time_title">报警时间</span>
          <el-date-picker
            prefix-icon="el-icon-date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="startTime"
            type="datetime"
            placeholder="选择开始日期时间"
          >
          </el-date-picker>
          <span style="padding:4px">-</span>
          <el-date-picker
            class="content_item"
            prefix-icon="el-icon-date"
            v-model="endTimes"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择结束日期时间"
          >
          </el-date-picker>
          <el-button class="query" @click="warningQuerys">查询</el-button>
          <el-button class="import" @click="deletes(null, true)"
            >删除</el-button
          >
          <el-button class="import" @click="exportWarning">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="deptName" label="店名" width="180">
        </el-table-column>
        <el-table-column prop="deviceFeature" label="设备型号" width="180">
        </el-table-column>
        <el-table-column prop="assetCode" label="资产编码"> </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI"> </el-table-column>
        <el-table-column prop="warningType" label="报警类型">
          <template slot-scope="scope">
            {{ warningTypeList[scope.row.warningType] }}
          </template>
        </el-table-column>
        <el-table-column prop="warningTime" label="报警时间">
          <template slot-scope="scope">{{
            scope.row.warningTime | formattingTime
          }}</template>
        </el-table-column>
        <el-table-column prop="status" label="处理状态">
          <template slot-scope="scope">{{
            scope.row.status == 1 ? "已处理" : "未处理"
          }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="assetCode" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="edit el-icon-edit"
              size="small"
              @click="edit(scope.row)"
            >
              编辑
            </el-button>
            <i class="app-operation-line" />
            <el-button
              type="text"
              class="deletes"
              size="small"
              @click="deletes(scope.row, false)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="12"
        :current-page="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last">尾页</span>
    </div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form label-position="left" label-width="80px" :model="editData">
        <el-form-item label="店名:">
          <p>{{ editData.deptName }}</p>
        </el-form-item>
        <el-form-item label="设备型号:">
          <p>{{ editData.deviceFeature }}</p>
        </el-form-item>
        <el-form-item label="资产编码:">
          <p>{{ editData.assetCode }}</p>
        </el-form-item>
        <el-form-item label="IMEI:">
          <p>{{ editData.deviceImei }}</p>
        </el-form-item>
        <el-form-item label="报警时间:">
          <p>{{ editData.warningTime | formattingTime }}</p>
        </el-form-item>
        <el-form-item label="处理状态:">
          <el-select v-model="editState" placeholder="请选择">
            <el-option
              v-for="item in editStateItem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            style="margin-top: 10px"
            type="textarea"
            :autosize="{ minRows: 4 }"
            maxlength="200"
            resize="none"
            placeholder="最多输入200个字符,支持英文、字母、下划线汉字"
            v-model="editData.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editWarnings">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  warningQuery,
  deleteWarning,
  exportWarnings,
  editWarning
} from "@/api/abnormalAdmin/abnormalWarning.js";

export default {
  name: "index",
  data() {
    return {
      editStateItem: [
        {
          value: 1,
          label: "已处理"
        },
        {
          value: 2,
          label: "未处理"
        }
      ],
      editState: "",
      editData: {},
      dialogVisible: false,
      keyword: "",
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      deviceCode: "",
      tableData: [],
      state: "",
      pageuers: 1,
      warningStatusItem: [
        {
          value: "1",
          label: "已处理"
        },
        {
          value: "2",
          label: "未处理"
        }
      ],
      warningType: "",
      warningSelection: [],
      startTime: null,
      endTimes: null,
      warningTypeList: [
        "全部告警解除",
        "温度高温报警",
        "温度高温报警解除",
        "温度低温告警",
        "温度低温告警解除",
        "通讯异常告警",
        "通讯异常告警解除",
        "探头故障告警",
        "探头故障告警解除",
        "位置异常报警",
        "断电报警",
        "流量服务中止报警",
        "连续断电告警"
      ]
    };
  },
  filters: {},
  created() {
    this.warningQuerys();
  },
  methods: {
    handleClose(done) {
      done();
    },
    //编辑
    editWarnings() {
      let data = this.editData;
      this.editData.status = this.editState;
      console.log();
      editWarning(data).then(res => {
        console.log(res.desc);
        if (res.data.desc === "SUCCESS") {
          this.dialogVisible = false;
          this.warningQuerys();
        }
      });
    },
    //导出
    exportWarning() {
      this.$store.commit("SET_LOADING", true);
      exportWarnings({})
        .then(res => {
          // console.log(res);
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss;
          const content = res.data; //返回的内容
          console.log(res.data);
          let _this = this;
          if (res.data.type !== "application/msexcel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              _this.$message({
                showClose: true,
                message: str.desc,
                type: "error"
              });
              if (str.resultCode == 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
          } else {
            const fileName = "异常报警信息列表" + date.toString() + ".xls"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
    //删除
    deletes(data, type) {
      let datas = { warningId: [] };
      if (type) {
        console.log(1111);
        for (let i = 0; i < this.warningSelection.length; i++) {
          console.log(this.warningSelection[i].warningId);
          // datas.warningId.push()
          datas.warningId.push(this.warningSelection[i].warningId);
        }
      } else {
        datas.warningId.push(data.warningId);
      }
      console.log(datas);
      deleteWarning(datas).then(res => {
        this.warningQuerys();
        console.log(res);
      });
    },
    //编辑
    edit(data) {
      console.log(data);
      this.editData = data;
      this.editState = data.status;
      this.dialogVisible = true;
    },
    handleSelectionChange(val) {
      this.warningSelection = val;
      console.log(val);
    },
    warningQuerys() {
      let data = {
        keyword: this.keyword,
        warningStartTime: this.startTime,
        warningEndTime: this.endTimes,
        warningStatus: this.state,
        limit: 12,
        curPage: this.currentPage,
        warningType: this.warningType
      };
      warningQuery(data).then(res => {
        console.log(res.data.data.pages);
        this.tableData = res.data.data.records;
        this.recordCount = res.data.data.total;
        this.pageuers = res.data.data.pages;
        // console.log(this.tableData)
      });
    },
    remove(data) {
      console.log(data);
    },
    //当前页
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.warningQuerys();
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.warningQuerys();
      // let data = {curPage: 1, limit: 12};
    },
    //分页最后一页
    last() {
      this.tableData = [];
      this.currentPage = Math.ceil(this.recordCount / 12);
      this.pages = Math.ceil(this.recordCount / 12);
      this.warningQuerys();
      // let data = {curPage: this.pages, limit: 12};
    }
  }
};
</script>

<style scoped>
.deletes {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #df2d2d;
  letter-spacing: -0.04px;
}

/*多选框样式*/
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266 !important;
}

/*分页样式*/
.app-page-container {
  margin-top: 20px;
}

.app-page-container .el-pagination {
  display: inline-block;
}

.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}

.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}

.app-operation-line {
  display: inline-block;
  border-right: 1px solid #e2e2e2;
  height: 13px;
  margin: 0 10px;
}

.table {
  margin-top: 20px;
}

.warning_time_title {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: -0.04px;
  margin-right: 5px;
}

.edit {
  font-size: 14px;
  color: #6e7790;
}

.edit:hover {
  color: #0cc;
}

.content_item {
  margin-right: 10px;
}

.content_input {
  width: 246px;
  height: 42px;
}

.content_state {
  width: 150px;
}

/*多选框样式*/
/deep/ .el-checkbox-group {
  display: inline-block;
}

/deep/ .el-checkbox {
  margin-right: 20px !important;
}
</style>
